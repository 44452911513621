/**
 * Bar component styles
 */
.bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .line {
    height: 20px;
    width: 100%;
  }

  .blue {
    background-color: #004a87;
  }

  .gray {
    background-color: #1d1d1b;
  }
}
