/**
 * Navbar component styles
 */
.custom-navbar {
  background-color: transparent;
  transition: all 250ms;

  .logo {
    width: 220px;

    @media (max-width: 350px) {
      width: 150px;
    }
  }

  .expanded,
  &.with-background {
    background-color: #292929;
  }

  .nav-link {
    color: #ffffff !important;
    margin: 2rem 2rem 1rem 0;
    transition: all 250ms;

    .bottom-line {
      border-color: #ffffff;
      margin-top: 0;
      opacity: 0;
      transition: all 250ms;
      width: 0;
    }

    &:hover > .bottom-line {
      opacity: 1;
      width: 100%;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 993px) {
      text-align: right;
      margin: 0;
    }
  }
}
