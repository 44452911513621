@import '~rfs/scss';
@import '../../variables.scss';

/**
 * Units view component styles
 */
.units-carousel {
  @include margin-bottom(3rem);

  padding-left: 0 !important;

  .units-card {
    transform: translateY(8px);
    transition: all 250ms;
    width: 350px;

    @media (max-width: 350px) {
      width: 100%;
    }

    &:hover {
      transform: translateY(0);
    }

    .header-img {
      width: 100%;
      height: 200px;
    }

    .information {
      @include padding(1rem);

      color: #ffffff;
      background-color: map-get($map: $colors, $key: 'primary');

      &.dark {
        background-color: #1d1d1b;

        .open-modal {
          background-color: map-get($map: $colors, $key: 'primary');
        }
      }

      .title,
      .subtitle {
        @include font-size(1.4rem);

        margin-bottom: 0;
      }

      .subtitle {
        @include margin-bottom(1rem);

        font-weight: bold;
      }

      .open-modal {
        background-color: #1d1d1b;
        border: 0;
      }
    }
  }
}

.custom-modal {
  .modal-content {
    border: 0;
  }

  .custom-modal-header {
    background-color: map-get($map: $colors, $key: 'primary');
    color: #ffffff;

    span {
      color: #ffffff;
    }
  }

  .variant {
    .title {
      @include font-size(1.2rem);
      @include margin(1rem 0);

      text-align: center;
      text-transform: uppercase;
    }

    &:first-of-type .title {
      margin-top: 0;
    }
  }
}
