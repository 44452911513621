/**
 * App main colors
 */

$colors: (
  'primary': #004a87,
  'white': #ffffff,
);

$sizes: (
  'small-title': 1.5rem,
  'small-subtitle': 1rem,
);
