@import '~rfs/scss';
@import '../../variables.scss';

/**
 * Services view component styles
 */
.services-container {
  .paragraph {
    @include padding-bottom(1.5rem);

    border-bottom: 2px solid #386186;
    width: 300px;
  }

  .service-icon {
    width: 100px;
  }

  .services-cards {
    .custom-card {
      color: #ffffff;
      display: flex;
      height: 250px;
      justify-content: center;

      &.white {
        color: inherit;

        .inner-card .information {
          background-color: #ffffffbd;

          &:before {
            border-bottom: 12px solid #ffffffbd;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
          }
        }
      }

      @media (min-width: 992px) {
        height: 300px;
        width: 300px;
      }

      .inner-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        transition: all 250ms;

        &:hover {
          transform: translateY(-5px);
        }

        &.background {
          background-size: cover;
          background-position: center;

          &.logistics-service {
            background-image: url('../../images/services/logistics.jpg');
          }

          &.personal-service {
            background-image: url('../../images/services/personal.jpg');
          }

          &.attention-service {
            background-image: url('../../images/services/attention.jpg');
          }

          &.licenses-service {
            background-image: url('../../images/services/licenses.jpg');
          }

          &.communication-service {
            background-image: url('../../images/services/communication.jpg');
          }

          &.insurance-service {
            background-image: url('../../images/services/insurance.jpg');
          }
        }

        .information {
          @include padding(1rem);

          background-color: #004176bd;
          position: relative;

          &:before {
            border-bottom: 12px solid #004176bd;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            content: '';
            display: block;
            height: 0;
            left: 0;
            left: 8px;
            position: absolute;
            top: -12px;
            width: 0;
          }

          .title {
            @include font-size(1rem);
          }

          .description {
            @include font-size(0.9rem);
          }
        }
      }
    }
  }

  .staff-container {
    @include padding-bottom(3rem);
    @include padding-top(3rem);

    background-image: url('../../images/background/staff.jpg');
    background-size: cover;
    color: #ffffff;

    .logo-container {
      @include padding-bottom(2rem);
      @include padding-top(2rem);

      background-color: #00000050;

      .logo {
        width: 300px;

        @media (max-width: 350px) {
          width: 100%;
        }
      }
    }
  }
}
