@import '~rfs/scss';

/**
 * Contact view component styles
 */

.contact-container {
  @include padding-bottom(4rem);

  .contact-cards {
    .card-holder {
      padding: 1rem 2rem;

      @media (max-width: 768px) {
        padding: 0.5rem 1rem;
      }
    }

    .inner-card {
      background-color: #e6e7e8;
      height: 120px;
    }

    .right {
      text-align: right;

      .icon-container {
        order: 1;
      }

      @media (max-width: 768px) {
        text-align: left;

        .icon-container {
          order: 0;
        }
      }
    }

    .left {
      text-align: left;
    }

    .center {
      margin: auto;
    }

    .icon {
      max-height: 75px;
    }

    .icon-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .information {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
      }
    }
  }

  .custom-input {
    @include margin-bottom(1rem);

    border-radius: 0;
    border-color: #606060;

    @media (min-width: 768px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .send-button-container {
    @include margin-top(1.5rem);

    .send-button {
      background-color: #004a87;
      width: 300px;

      @media (max-width: 350px) {
        width: 100%;
      }
    }
  }
}

.custom-toast {
  background-color: #ffffff !important;
  position: fixed;
  right: 10px;
  top: 110px;
  z-index: 9999;
}
