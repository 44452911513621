@import '~rfs/scss';
// Style variables
@import '../../variables.scss';

/**
 * About component styles
 */
.about-container {
  .title {
    @include font-size(map-get($map: $sizes, $key: 'small-title'));

    color: #ffffff;
    font-weight: bold;
    text-align: center;
  }

  .information {
    @include padding-bottom(3rem);
    @include padding-top(3rem);

    background-image: url('../../images/background/about.jpg');
    background-size: cover;
    background-position: center;

    .rows {
      margin-bottom: 3rem;
    }

    .description {
      color: #ffffff;
      margin: 0 auto;
      text-align: center;
      width: 300px;

      &.large {
        width: 800px;

        @media (max-width: 992px) {
          width: 100%;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .margin-in-small {
      @media (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
  }
}
