@import '~rfs/scss';

/**
 * Home component styles
 */
.home-screen {
  @include padding(0 1rem);

  align-items: center;
  background-image: url('../../images/background/home.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  @media (max-height: 650px) {
    @include padding-top(300px);
  }

  .carousel-inner {
    height: 100%;
  }

  .home-item {
    align-items: center;
    color: #ffffff;
    display: flex !important;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;

    .middle-paragraph {
      text-align: center;
      width: 500px;

      @media (max-width: 550px) {
        width: 100%;
      }
    }

    .subtitle {
      @include font-size(2.2rem);
      font-weight: 300;
    }

    .large {
      @include font-size(5rem);

      font-weight: 600;
    }

    .uppercase {
      text-transform: uppercase;
    }

    .experience-badge {
      width: 200px;

      @media (max-width: 500px) {
        width: 150px;
      }
    }
  }
}
