@import '~rfs/scss';

/**
 * Footer view component styles
 */
.footer {
  @include font-size(0.85rem);
  @include padding(2rem 1rem);

  background-color: #191919;
  color: #6c7470;

  .logo {
    @include margin-bottom(0.8rem);

    width: 150px;
  }

  .description {
    text-align: justify;
  }

  .title {
    @include font-size(1rem);

    color: #ffffff;
  }

  a {
    color: #154965;
  }

  p {
    margin: 0;
  }

  hr {
    border-color: #909090;
    border-width: 2px;
  }
}

.copyright-container {
  @include font-size(0.9rem);
  @include padding(1rem 0);

  background-color: #000000;
  color: #6c7470;

  .white,
  .white a {
    display: inline;
    color: #d9ddda;
  }
}
