@import '~rfs/scss';
// Style variables
@import '../variables.scss';

.header {
  @include padding-bottom(4rem);
  @include padding-top(4rem);

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .title {
    @include font-size(map-get($map: $sizes, $key: 'small-title'));

    color: map-get($map: $colors, $key: 'primary');
    font-weight: 700;

    &.white {
      color: #ffffff;
    }
  }

  .subtitle {
    @include font-size(map-get($map: $sizes, $key: 'small-subtitle'));

    color: map-get($map: $colors, $key: 'primary');
  }

  .description {
    width: 800px;

    @media (max-width: 850px) {
      @include padding-left(1rem);
      @include padding-right(1rem);

      width: 100%;
    }
  }
}
