.floating-button {
  bottom: 15px;
  height: 100px;
  position: fixed;
  right: 15px;
  width: 100px;

  @media (max-width: 576px) {
    width: 70px;
    height: 70px;
  }
}
